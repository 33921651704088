import React, { useState } from 'react';
import axios from 'axios';
import { Container, Navbar, Nav, Row, Col, Button, Card, Form, Alert } from 'react-bootstrap';
import { Typography } from '@mui/material';
import { Link, Element } from 'react-scroll';
import './App.css';

const App = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    phone: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState({});
  const [formSuccess, setFormSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = 'Name is required';
    }
    if (!formData.email && !formData.phone) {
      errors.contact = 'Either email or phone is required';
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      try {
        const response = await axios.post('https://d42a51yfghin2.cloudfront.net/outer/contact', formData);
        console.log('Form submitted successfully:', response.data);
        setFormSuccess(true);
        setFormData({
          name: '',
          email: '',
          company: '',
          phone: '',
          message: ''
        });
        setFormErrors({});
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <div>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="#home">Familiar Software Solutions</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="home" smooth={true} duration={500}>Home</Nav.Link>
              <Nav.Link as={Link} to="about" smooth={true} duration={500}>About</Nav.Link>
              <Nav.Link as={Link} to="services" smooth={true} duration={500}>Services</Nav.Link>
              <Nav.Link as={Link} to="contact" smooth={true} duration={500}>Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Element name="home" className="element">
        <div className="hero-section d-flex align-items-center justify-content-center">
          <Container className="text-center">
            <Typography variant="h2" gutterBottom>Welcome to Familiar Software Solutions</Typography>
            <Typography variant="h5" gutterBottom>Innovative Solutions for a Connected World</Typography>
            <Button variant="primary" size="lg">Get Started</Button>
          </Container>
        </div>
      </Element>

      <Container className="my-5">
        <Element name="about" className="element">
          <Row id="about" className="py-5">
            <Col md={6}>
              <Typography variant="h4" gutterBottom>About Us</Typography>
              <Typography variant="body1">
                Based in the heart of Northern British Columbia, Familiar Software Solutions is dedicated to providing cutting-edge software solutions to help businesses thrive in the digital age. We understand the unique challenges and opportunities of our region and are committed to delivering innovative, customized solutions that drive success and growth. Partner with us to leverage our expertise and experience, and take your business to the next level.
              </Typography>
              <Typography variant="body1" className="mt-3">
                Our team of seasoned professionals is passionate about technology and dedicated to client success. We work closely with our clients to understand their needs and deliver solutions that exceed expectations. Whether you are a small business looking to streamline operations or a large enterprise seeking advanced software solutions, we are here to help.
              </Typography>
              <Typography variant="body1" className="mt-3">
                Join the growing number of businesses in Northern BC that trust Familiar Software Solutions to provide reliable, innovative, and efficient software solutions. Contact us today to learn how we can support your business goals and drive your success in the digital era.
              </Typography>
            </Col>
            <Col md={6}>
              <img src="/logo-small/logo-black.png" alt="About Us" className="img-fluid" />
            </Col>
          </Row>
        </Element>

        <Element name="services" className="element">
          <Row id="services" className="py-5">
            <Typography variant="h4" className="text-center mb-4">Our Services</Typography>
            <Col md={4}>
              <Card className="mb-4 service-card">
                <Card.Body>
                  <Card.Title>Web Development</Card.Title>
                  <Card.Text>
                    Our web development services are designed to build responsive and engaging websites that meet your business needs and enhance your online presence.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-4 service-card">
                <Card.Body>
                  <Card.Title>Systems Development</Card.Title>
                  <Card.Text>
                    We specialize in developing robust and scalable systems tailored to streamline your operations and improve overall efficiency.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-4 service-card">
                <Card.Body>
                  <Card.Title>Data Analysis</Card.Title>
                  <Card.Text>
                    Our data analysis services help you turn data into actionable insights, enabling better decision-making and strategic planning.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-4 service-card">
                <Card.Body>
                  <Card.Title>Marketing</Card.Title>
                  <Card.Text>
                    We offer comprehensive marketing services to help you reach your target audience effectively and grow your business.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-4 service-card">
                <Card.Body>
                  <Card.Title>Research</Card.Title>
                  <Card.Text>
                    Our Research as a Service (RaaS) offering provides comprehensive research solutions tailored to meet the specific needs of your organization. We specialize in delivering actionable insights and data-driven strategies that empower you to make informed decisions and stay ahead in your industry.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-4 service-card">
                <Card.Body>
                  <Card.Title>Professional Courses Development</Card.Title>
                  <Card.Text>
                    We offer professional courses development to help you and your team stay ahead with the latest industry skills and knowledge.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-4 service-card">
                <Card.Body>
                  <Card.Title>Professional Employee Training</Card.Title>
                  <Card.Text>
                    Our professional employee training programs are designed to enhance your team's skills and performance, ensuring your business stays competitive.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Element>

        <Element name="contact" className="element">
          <Row id="contact" className="py-5">
            <Typography variant="h4" className="text-center mb-4">Contact Us</Typography>
            <Col md={8} className="mx-auto">
              {formSuccess && <Alert variant="success">Form submitted successfully!</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="Enter your name" 
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    isInvalid={!!formErrors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control 
                    type="email" 
                    placeholder="Enter your email" 
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    isInvalid={!!formErrors.contact}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.contact}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Company</Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="Enter your company" 
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="Enter your phone" 
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    isInvalid={!!formErrors.contact}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.contact}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Control 
                    as="textarea" 
                    rows={3} 
                    placeholder="Enter your message" 
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button variant="primary" type="submit">Submit</Button>
              </Form>
            </Col>
          </Row>
        </Element>
      </Container>

      <footer className="text-center py-4">
        <Container>
          <Typography variant="body2">
            &copy; 2024 Familiar Software Solutions. All rights reserved.
          </Typography>
        </Container>
      </footer>
    </div>
  );
}

export default App;
